import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { PORTFOLIOS_FILTER_BY } from '@/views/pages/liquidity/portfolios/portfolio/models/constants';
import { SortDirection } from '@/utils/types';

export enum SORT_FIELD {
  LIQUIDITY_IN_USD = 0,
  APR = 1,
}

export type SortModel = {
  sortBy: string;
  direction: SortDirection;
};

export const usePortfoliosFilter = defineStore('portfoliosFilter', () => {
  const filterBy = ref<'all' | 'local' | 'crosschain'>(PORTFOLIOS_FILTER_BY.all);
  const filterSearch = ref<string>('');
  const isStakedOnly = ref<boolean>(false);

  const sortModel = reactive<SortModel>({
    sortBy: 'none',
    direction: 'desc',
  });

  const setDefault = (by: string) => {
    filterBy.value = PORTFOLIOS_FILTER_BY.all;
    filterSearch.value = '';
    isStakedOnly.value = false;
    sortModel.sortBy = by;
    sortModel.direction = 'desc';
  };

  return {
    filterBy: computed(() => filterBy),
    filterSearch: computed(() => filterSearch),
    isStakedOnly: computed(() => isStakedOnly),
    sortModel,
    setDefault,
  };
});
