import { computed } from 'vue';
import { seo } from '@/composables/seo/useSeo';

export function usePortfoliosSEO() {
  const SEO_PORTFOLIOS = computed(() => seo.value.SEO_PORTFOLIOS);

  return {
    title: computed(() => SEO_PORTFOLIOS.value.TITLE),
    description: computed(() => SEO_PORTFOLIOS.value.DESCRIPTION),
    keywords: computed(() => SEO_PORTFOLIOS.value.KEYWORDS),
  };
}
