import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
import { NetworkValue } from '@/constants/NETWORK_NAMES.types';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type ReactiveFunction<TParam> = () => TParam;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: number;
  /** BigInt for number */
  BigInt: number;
  /** Built-in scalar representing a local date-time */
  LocalDateTime: string;
  /** Long type */
  Long: number;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
  TokenIntervalData_orderBy: string;
};

export enum IntervalType {
  Days_7 = 'DAYS_7',
  Days_30 = 'DAYS_30',
  Hours_1 = 'HOURS_1',
  Hours_24 = 'HOURS_24',
  Minutes_10 = 'MINUTES_10',
  Months_3 = 'MONTHS_3',
  Months_6 = 'MONTHS_6',
  Months_12 = 'MONTHS_12',
}

export enum NetworkEnvironment {
  Mainnet = 'MAINNET',
  Testnet = 'TESTNET',
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type QueryNetworkValue = Uppercase<
  Exclude<NetworkValue, 'Wrapped_Milkomeda_Cardano' | 'Eth_Sepolia'> | 'ETHEREUM'
>;

export type GetStatPortfoliosQuery = {
  __typename?: 'Query';
  portfolioIntervalDatas?: Array<{
    __typename?: 'PortfolioIntervalDataDto';
    id?: string | null;
    dateTime?: string | null;
    tvlUSD?: number | null;
    volume24hUSD?: number | null;
  } | null> | null;
  tokenIntervalDatas?: Array<{
    __typename?: 'TokenIntervalData';
    id?: string | null;
    dateTime?: string | null;
    priceUSD?: number | null;
  } | null> | null;
};

export type GetStatPortfoliosQueryVariables = Exact<{
  start?: InputMaybe<Scalars['LocalDateTime']>;
  end?: InputMaybe<Scalars['LocalDateTime']>;
  interval?: InputMaybe<IntervalType>;
  portfolioAddress?: InputMaybe<Scalars['String']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  network?: InputMaybe<QueryNetworkValue>;
  tokenIntervalDatasOrderBy?: InputMaybe<Scalars['TokenIntervalData_orderBy']>;
}>;

export type GetPortfoliosListQueryVariables = Exact<{
  network?: InputMaybe<QueryNetworkValue>;
}>;

export function toQueryNetwork(networkValue: NetworkValue): QueryNetworkValue {
  if (networkValue === 'Wrapped_Milkomeda_Cardano') {
    return 'MILKOMEDA_CARDANO';
  }

  if (networkValue === 'Eth_Sepolia') {
    return 'ETHEREUM';
  }

  return <QueryNetworkValue>networkValue.toUpperCase();
}

const GetStatPortfoliosDocument = gql`
  query getStatPortfolios(
    $start: LocalDateTime
    $end: LocalDateTime
    $interval: IntervalType
    $tokenAddress: String
    $network: Network
    $tokenIntervalDatasOrderBy: TokenIntervalData_orderBy
  ) {
    tokenIntervalDatas(
      first: -1
      skip: 0
      where: {
        tokenAddress: $tokenAddress
        interval: $interval
        dateTime_gt: $start
        dateTime_lt: $end
        network: $network
      }
      orderBy: $tokenIntervalDatasOrderBy
    ) {
      id
      dateTime
      priceUSD
    }
  }
`;

export function useGetStatPortfoliosQuery(
  variables:
    | GetStatPortfoliosQueryVariables
    | VueCompositionApi.Ref<GetStatPortfoliosQueryVariables>
    | ReactiveFunction<GetStatPortfoliosQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<GetStatPortfoliosQuery, GetStatPortfoliosQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetStatPortfoliosQuery, GetStatPortfoliosQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetStatPortfoliosQuery, GetStatPortfoliosQueryVariables>
      > = {},
) {
  return VueApolloComposable.useQuery<GetStatPortfoliosQuery, GetStatPortfoliosQueryVariables>(
    GetStatPortfoliosDocument,
    variables,
    options,
  );
}
